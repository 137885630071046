<template>
<div>
  <section class="section pt">
    <div class="ym-toptitle-box">
      <div id="toptitle" class="ym-toptitle">
        <div class="level">
          <h2 class="title level-left">Resetuj hasło</h2>
        </div>
      </div>
    </div>
    <div class="columns is-variable  is-multiline">
      <div class="column is-12">
        <ValidationObserver v-slot="{ handleSubmit, errors }">
          <form class="password_set" @submit.prevent="handleSubmit(onSubmit)">          
            <div :class="errors['password'] && errors['password'].length > 0 ? 'columns is-variable  is-multiline v-field v-error' : 'columns is-variable  is-multiline'">
              <div class="column ym-column is-12">
                <div class="ym-label">
                  <label for="password">Wpisz nowe hasło</label>
                </div>
              </div>
              <div class="column ym-column is-12">
                <div class="field">
                  <div class="control is-medium has-icons-right is-clearfix">
                    <ValidationProvider rules="required" name="password">
                      <input id="password" type="password" name="password" v-model="password" class="input is-medium" />
                      <span  class="icon is-right has-text-primary is-clickable">
                        <i @click="passwordReveal" class="mdi mdi-eye mdi-24px"></i>
                      </span>      
                    </ValidationProvider>                        
                    <span v-if="errors['password'] && errors['password'].length > 0" class="help is-danger">{{ errors['password'][0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-variable  is-multiline">
                <div class="column ym-column is-12">
                    <div class="field">
                        <div class="control is-medium">
                          <button class="button is-medium is-dark is-justify is-fullwidth"><span>Zapisz nowe hasło</span><icon name="check"></icon></button>
                        </div>
                    </div>
                </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>    
</div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import {mapActions} from 'vuex'

  export default {
    name: 'PasswordSet',
    props: ['token'],
    components: {
      ValidationObserver,
      ValidationProvider
    },      
    data: function() {
      return {
        password: '',      
      }
    },
    methods: {
      ...mapActions({
        passwordSet: 'auth/passwordSet'
      }),        
      onSubmit() {
        this.passwordSet({password: this.password, token: this.token})
          .then((resp) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: resp.alert[1],
              position: 'is-bottom',
              type: resp.alert[0] === 'negative' ? 'is-danger' : 'is-success'
            }) 
            this.$router.push('/login')          
          })
      },
      passwordReveal: function(e) {
      let password = document.querySelector('#password')
        if (password.type === "password") {
          e.target.classList.replace('mdi-eye', 'mdi-eye-off')
          password.type = "text";
        } else {
          e.target.classList.replace('mdi-eye-off', 'mdi-eye')
          password.type = "password";
        }      
      }    
    }
  }    
</script>
